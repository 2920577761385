import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'

import { responsiveTitle1 } from '../components/typography.module.css'


const AboutMePage = props => {
  return (
    <Layout>
      <SEO title='Archive' />
      <Container>
        <h1 className={responsiveTitle1}>About Ceddy</h1>
        {/* {projectNodes && projectNodes.length > 0 && <ProjectPreviewGrid nodes={projectNodes} />} */}
        {/* <h4>Welcome to my 👑dom</h4> */}
        <h3>🌌🪐🌙🚀☄☄️🛰🧑🏾‍🚀🌕👽🛸</h3>
        <br />
        <p>
          .   *     .       *    .🌙      *    .  *    *     *   ☄️.    *      *    .      🌍*      .     .    *   💫 *    .      *      .     .  *.       ✨ *    .  *     🌑       .    *    ☄️     *    .  *     🌕  .    *    .  *  .      *    .      *.    *     *   .  . *
          🛸　　　 　🌎　°　　🌓　•　　.°•　　　🚀 ✯
          　　　★　*　　　　　°　　　　🛰 　°·　　                           🪐
          .　　　•　° ★　•  ☄
          ▁▂▃▄▅▆▇▇▆▅▄▃▁▂.
        </p>
      </Container>
    </Layout>
  )
}

export default AboutMePage
